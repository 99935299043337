import React from 'react';


const data = [
  { id: 1, date: "2017-18", heading: 'AISHE Certificate', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/AISHE 2017-18.pdf' },
  { id: 2, date: "2018-19", heading: 'AISHE Certificate', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/AISHE 2018-19.pdf' },
  { id: 3, date: "2022-23", heading: 'AISHE Certificate', profileurl: 'https://static.muoninfotech.com/./assets/aishecertificate/C-11956_certificateold_-DCF 2022-23.pdf' },
  { id: 4, date: "2023-24", heading: 'AISHE Certificate', profileurl: 'https://static.muoninfotech.com/./assets/aishecertificate/C-11956_certificateold_-DCF23-24.pdf' },

  
];

const AisheCertificate = () => {
  return (
    <div className="container">
      {data.map((item) => (
        <div key={item.id} className="card my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <p>Year : {item.date}</p>
              </div>
              <div className="col-md-6">
                <h3>{item.heading}</h3>
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary" type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = item.profileurl;
                  }}>Download</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AisheCertificate;
